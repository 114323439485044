import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = ["href"]

import {getEncryptedData} from '@/api/common'
import {decryptedData} from '@/utils/encrypt'
import {ref} from "vue";
import {type WebInfo} from '@/api/type'


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {


const dataSource = ref<WebInfo>({
  web_title: '',
  web_domains: [],
  service_email:'',
  web_description:[],
})

const getData = async () => {
  const result = await getEncryptedData()
  try {
    const jsonStr = decryptedData(result)
    const data = JSON.parse(jsonStr)

    Object.assign(dataSource.value, data)

    console.log("---------------",dataSource.value);
  } catch (error) {
    console.log("error", error)
  }
}

getData()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(dataSource.value.web_title) + "地址发布页", 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataSource.value.web_domains, (url, i) => {
        return (_openBlock(), _createElementBlock("div", { key: i }, [
          _createElementVNode("a", {
            class: "button",
            href: url,
            target: "_blank"
          }, _toDisplayString(dataSource.value.web_description[i]) + " ：" + _toDisplayString(url) + " 点击进入", 9, _hoisted_3)
        ]))
      }), 128)),
      _cache[0] || (_cache[0] = _createElementVNode("h1", null, [
        _createElementVNode("span", null, "临时地址会定期更换，如果被蔷导致无法打开可以稍后再试")
      ], -1))
    ])
  ]))
}
}

})