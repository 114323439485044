<template>
  <div class="container">
    <div class="card">
      <h1>{{dataSource.web_title}}地址发布页</h1>
      <div v-for="(url,i) in dataSource.web_domains" :key="i">
        <a  class="button" :href="url" target="_blank">{{dataSource.web_description[i]}} ：{{ url }} 点击进入</a>
      </div>
      <h1><span>临时地址会定期更换，如果被蔷导致无法打开可以稍后再试</span></h1>
    </div>
  </div>
</template>

<script setup lang="ts">

import {getEncryptedData} from '@/api/common'
import {decryptedData} from '@/utils/encrypt'
import {ref} from "vue";
import {type WebInfo} from '@/api/type'

const dataSource = ref<WebInfo>({
  web_title: '',
  web_domains: [],
  service_email:'',
  web_description:[],
})

const getData = async () => {
  const result = await getEncryptedData()
  try {
    const jsonStr = decryptedData(result)
    const data = JSON.parse(jsonStr)

    Object.assign(dataSource.value, data)

    console.log("---------------",dataSource.value);
  } catch (error) {
    console.log("error", error)
  }
}

getData()
</script>

<style scoped>
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0
}

article, aside, footer, header, nav, section {
  display: block
}

h1 {
  font-size: 2em;
  margin: .67em 0
}

figcaption, figure, main {
  display: block
}

figure {
  margin: 1em 40px
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

pre {
  font-family: monospace, monospace;
  font-size: 1em
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted
}

b, strong {
  font-weight: inherit
}

b, strong {
  font-weight: bolder
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em
}

dfn {
  font-style: italic
}

mark {
  background-color: #ff0;
  color: #000
}

small {
  font-size: 80%
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

audio, video {
  display: inline-block
}

audio:not([controls]) {
  display: none;
  height: 0
}

img {
  border-style: none
}

svg:not(:root) {
  overflow: hidden
}

button, input, optgroup, select, textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0
}

button, input {
  overflow: visible
}

button, select {
  text-transform: none
}

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0
}

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: 1px dotted ButtonText
}

fieldset {
  padding: .35em .75em .625em
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

progress {
  display: inline-block;
  vertical-align: baseline
}

textarea {
  overflow: auto
}

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

details, menu {
  display: block
}

summary {
  display: list-item
}

canvas {
  display: inline-block
}

template {
  display: none
}

[hidden] {
  display: none
}

/*# sourceMappingURL=normalize.min.css.map */

* {
  box-sizing: border-box;
}


.container {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1.4;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  height: 100%;
}

.card {
  background: #fff;
  background-size: contain;
  border-radius: 10px;
  box-shadow: 0 10px 30px -5px rgba(60, 60, 60, 0.2);
  text-align: center;
  padding: 30px 60px;
}

.card img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  margin: auto auto 15px;
  display: block;
}

.card h1 {
  font-size: 22px;
  margin: 10px auto 0;
  letter-spacing: 1px;
}

.card h2 {
  margin: auto;
  color: #b1b6c6;
  font-weight: 300;
  font-size: 14px;
}

.card .button {
  display: block;
  text-decoration: none;
  background: #3b70fc;
  color: #f9f9f9;
  padding: 12px;
  border: none;
  border-radius: 25px;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 700;
  min-width: 200px;
  transition: 0.2s;
}

.card .button span {
  font-size: 20px;
  line-height: 1;
  vertical-align: top;
}

.card .button:hover {
  background: #1856fb;
}

.navbar-brand {
  display: inline-flex;
  align-items: center;
  font-weight: var(--tblr-font-weight-bold);
  margin: 0;
  line-height: 1;
  gap: 0.5rem;
}
</style>